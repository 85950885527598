import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';

// import { renderRoutes } from 'react-router-config';
import './App.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const BoringLayout = React.lazy(() => import('./containers/BoringLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {
  
  constructor() {
    super();
    this.state = { isAuthenticated: false, user: null, token: ''};
  }

  logout = () => {
    this.setState({isAuthenticated: false, token: '', user: null})
  };
  
  onSuccess = (e) => {
    this.setState({isAuthenticated:true, token: e.tokenObj.id_token})
  };
  
  onFailure = (error) => {
    console.log('failure');
    alert(error);
  }
  render() {

    let content = !!this.state.isAuthenticated ?
      (
        <HashRouter>
            <React.Suspense fallback={loading()}>
              <Switch>
                <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                <Route path="/" name="Home" render={props => <BoringLayout {...props} onLogout={this.logout}/>} />
              </Switch>
            </React.Suspense>
        </HashRouter>
      ) :
      (
        <div>
            <GoogleLogin
                clientId="629153235324-gvnj2vnipaofqiblbjl3ii0ned66aml3.apps.googleusercontent.com"
                buttonText="Login"
                onSuccess={this.onSuccess}
                onFailure={this.onFailure}
                responseType = "id_token"
                hostedDomain = "boringcompany.com"
            />
        </div>
      );
    return content;
  }
}

export default App;
